<template xmlns="http://www.w3.org/1999/html">
    <section id="import-csv">
        <!-- Title -->
        <div class="container-fluid container-edit pt-5 pb-4">
            <h1 class="text-center title-mobile pb-2">{{ $t("importCsvHeader")}}</h1>
        </div>
        <!-- Form -->
        <form id="app" @submit.prevent="$bvModal.show('preview-modal')">
            <div class="d-flex justify-content-center mt-5">
                <div class="w-50">
                    <!-- Template downloader -->
                        <div class="form-group d-flex flex-column-reverse align-items-center justify-content-center text-center">
                            <p>{{ $t('importCsvObligatoryColumns') }}</p>
                            <label class="font-weight-bold mb-0">{{ $t('importCsvTemplateButton') }}</label>
                            <div class="d-flex flex-row justify-content-center">
                                <img :src="require('@/assets/img/arrow_direction.png')" alt="arrow" style="height: 24px; transform: rotate(270deg);" class="m-0 mt-4 align-self-center">
                                <a href="/import_template.csv" id="template-link"><img :src="require('@/assets/img/csv_icon.png')" alt="csv icon" style="width: 64px;" class="my-2 mx-0"></a>
                            </div>
                        </div>

                    <!-- Category selector -->
                    <div class="form-group">
                        <label for="category">{{ $t("productCategoryLabel") }} <span>*</span></label>
                        <b-form-input list="list-cat" v-model="chosenCategory" id="category" ref="categoryInput" :placeholder="$t('productChooseCategoryPlaceHolder')" @change="checkCategory"></b-form-input>
                        <b-form-datalist id="list-cat" :options="categories" text-field="name" value-field="name"></b-form-datalist>
                    </div>
                    <!-- Input file -->
                    <div class="form-group">
                        <label for="csvFile">
                            {{ $t("importCsvLabel") }} <span>*</span>
                            <b-button type="button" class="btn btn-info info-bubble" style="padding: 0 0 !important; margin-left: 4px;" v-b-popover.hover.top="$t('importCsvExplain')"><i class="fas fa-info-circle" /></b-button>
                        </label>
                        <input ref="csv-file-input" type="file" class="form-control-file" id="csvFile" accept=".csv" @change="importCsv" required>
                        <!-- Not good file warning -->
                        <div class="invalid-feedback" ref="isNotGoodFileWarn">
                            <div v-if="missingColumns.length !== 0" class="d-flex flex-column">
                                <p class="pr-1 m-0">{{ $tc('importCsvMissingColumnsMessage', missingColumns.length) }}</p>
                                <p>{{ missingColumns.join(' / ') }}</p>
                            </div>
                            <p v-else>{{ $t('importCsvWarning') }}</p>
                        </div>
                    </div>
                    <!-- Submit button -->
                    <div class="form-group d-flex justify-content-center mt-4">
                        <button type="submit" ref="submit-button" class="btn btn-primary" v-b-modal="'preview-modal'" disabled>{{ $t("importCsvSubmitButton") }}</button>
                    </div>
                </div>
            </div>
        </form>
        <!-- Review modal -->
        <b-modal id="preview-modal" centered :title="$t('importCsvPreview')">
            <!-- Number of products found -->
            <div class="d-flex flex-row">
                <p class="font-weight-bold mr-1">{{ $t('importCsvNumberProductsFound')}} :</p>
                <p>{{ this.newDataset.length }}</p>
            </div>
            <!-- Wrong products -->
            <div class="d-flex flex-row" v-if="wrongProducts.length !== 0">
                <p class="font-weight-bold mr-1">{{ $t('importCsvIndexesWrongProducts') }} :</p>
                <p>{{ wrongProducts.join(', ') }}</p>
            </div>
            <!-- Warn message if bad products -->
            <p v-if="wrongProducts.length !== 0" class="font-weight-bold w-100 m-0 pt-2 d-flex flex-row justify-content-end align-items-center">{{ $t('importCsvFileError') }}</p>
            <!-- Table of found products -->
            <table class="w-100">
                <thead>
                    <tr>
                        <th style="width: 5%" class="text-center">Index</th>
                        <th style="width: 10%" class="text-center">Name en</th>
                        <th style="width: 10%" class="text-center">Name fr</th>
                        <th style="width: 10%" class="text-center">Keywords en</th>
                        <th style="width: 10%" class="text-center">Keywords fr</th>
                        <th style="width: 10%" class="text-center">Description en</th>
                        <th style="width: 10%" class="text-center">Description fr</th>
                        <th style="width: 10%" class="text-center">Brand</th>
                        <th style="width: 10%" class="text-center">Price</th>
                        <th style="width: 10%" class="text-center">Ean</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="product in newDataset" :style="'background-color: ' + (isAGoodProduct(product) ? ((newDataset.indexOf(product) % 2) === 0 ? '#f5f5f5' : 'white') : '#ffcccb')" class="text-center">
                        <td style="width: 5%">{{ newDataset.indexOf(product) + 1 }}</td>
                        <td style="width: 10%" :class="!isNameValid(product.name.en) ? 'font-weight-bold text-danger' : ''">{{ product.name.en }}</td>
                        <td style="width: 10%" :class="!isNameValid(product.name.fr) ? 'font-weight-bold text-danger' : ''">{{ product.name.fr }}</td>
                        <td style="width: 10%">{{ product.keywords.en }}</td>
                        <td style="width: 10%">{{ product.keywords.fr }}</td>
                        <td style="width: 12%"><p style="max-height: 150px; overflow-y: auto" class="mb-0 mt-1">{{ product.description.en }}</p></td>
                        <td style="width: 12%"><p style="max-height: 150px; overflow-y: auto" class="mb-0 mt-1">{{ product.description.fr }}</p></td>
                        <td style="width: 10%" :class="!isBrandValid(product.brand) ? 'font-weight-bold text-danger' : ''">{{ product.brand }}</td>
                        <td style="width: 10%" :class="!isPriceValid(product.price) ? 'font-weight-bold text-danger' : ''">{{ product.price }}</td>
                        <td style="width: 10%" :class="!isEanValid(product.ean) ? 'font-weight-bold text-danger' : ''">{{ product.ean }}</td>
                    </tr>
                </tbody>
            </table>
            <!-- Warn message if bad products -->
            <p v-if="wrongProducts.length !== 0" class="font-weight-bold w-100 m-0 pt-2 d-flex flex-row justify-content-end align-items-center">{{ $t('importCsvFileError') }}</p>
            <template #modal-footer>
                <button type="button" class="btn btn-secondary" @click="$bvModal.hide('preview-modal')">{{ $t('importCsvCancelButton') }}</button>
                <button type="button" class="btn btn-primary" @click="handleSubmit(false)" v-if="wrongProducts.length === 0">{{ $t('importCsvSendButton') }}</button>
            </template>
        </b-modal>
        <!-- Response modal -->
        <b-modal id="response-modal" centered :title="$t('importCsvResult')">
            <!-- Success or error icon -->
            <div class="col-12 d-flex justify-content-center pb-3" v-show="tabExistingProducts.length === 0">
                <span class="container-success-icon" v-if="(tabExistingProducts.length === 0 && tabNewProducts.length !== 0) || (tabExistingProducts.length !== 0 && tabNewProducts.length === 0)">
                    <div class="shapeshifter play success-icon"></div>
                </span>
                <span class="error-icon" v-else>
                    <img ref="error-img" :src="require('@/assets/img/error.svg')" alt="error">
                </span>
            </div>
            <!-- Request result -->
            <div>
                <h5 class="title-mobile text-center pb-3" v-if="(numberNewProducts !== tabNewProducts.length) && forceSend">{{ numberNewProducts }} {{ $tc('importCsvNumberProductsAdded', numberNewProducts) }}</h5>
                <h5 class="title-mobile text-center pb-3" v-else-if="tabExistingProducts.length === 0">{{ tabNewProducts.length }} {{ $tc('importCsvNumberProductsAdded', tabNewProducts.length) }}</h5>
                <h5 class="title-mobile text-center pb-3" v-else-if="tabExistingProducts.length !== 0">{{ tabNewProducts.length }} {{ $tc('importCsvNumberProductsCanBeAdded', tabNewProducts.length) }}</h5>
                <h5 class="title-mobile text-center pb-3" v-else>{{ tabNewProducts.length }} {{ $tc('importCsvNumberProductsAdded', tabNewProducts.length) }}</h5>

                <h5 class="title-mobile text-center pb-3" v-if="tabExistingProducts.length !== 0 && !forceSend">{{ tabExistingProducts.length }} {{ $tc('importCsvNumberProductsAlreadyExist', tabExistingProducts.length) }}</h5>
                <p v-if="tabExistingProducts.length !== 0 && !forceSend" class="mb-0 mt-2">{{ $t('importCsvListProductsAlreadyExist') }}</p>
                <b-list-group id="duplicates-list" v-show="tabExistingProducts.length !== 0 && !forceSend">
                    <b-list-group-item v-for="ean in tabExistingProducts" :key="ean">{{ ean }}</b-list-group-item>
                </b-list-group>
                <p v-if="tabExistingProducts.length !== 0 && !forceSend && tabNewProducts.length !== 0" class="mb-0 mt-3 font-weight-bold">{{ $t('importCsvQuestionForceAdd') }}</p>
            </div>
            <template #modal-footer>
                <button type="button" class="btn btn-secondary" @click="$bvModal.hide('response-modal')" v-if="!((tabExistingProducts.length === 0 && tabNewProducts.length !== 0) || (tabExistingProducts.length !== 0 && tabNewProducts.length === 0))">{{ $t('importCsvCancelButton') }}</button>

                <button type="button" class="btn btn-primary" @click="$router.push('/category/list/child/' + getCategoryId())" v-if="(tabExistingProducts.length === 0 && tabNewProducts.length !== 0) || (tabExistingProducts.length !== 0 && tabNewProducts.length === 0)">Ok</button>
                <button type="button" class="btn btn-primary" @click="$bvModal.hide('response-modal'); handleSubmit(true)" v-else-if="tabExistingProducts.length !== 0">{{ $t('importCsvForceButton') }}</button>
            </template>
        </b-modal>
        <!-- Loading modal -->
        <b-modal id="loading-modal" centered hide-footer hide-header>
            <div class="d-flex flex-column justify-content-center align-items-center">
                <img :src="require('@/assets/img/loading.gif')" alt="loading">
                <span class="ml-2">{{ $t('importCsvSending') }}</span>
            </div>
        </b-modal>
        <!-- Images modal -->
        <b-modal id="images-modal" centered :title="$t('importCsvImagesProblem')">
            <div>
                <p>{{ $tc('importCsvImagesProblemExplanation', tabBadImages.length) }}</p>
            </div>
            <table class="w-100">
                <thead>
                    <tr>
                        <th class="text-center">Id</th>
                        <th class="text-center">Name</th>
                        <th class="text-center">Ean</th>
                        <th class="text-center">{{ $t('importCsvImageLink') }}</th>
                        <th class="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="product of tabBadImages">
                        <td class="text-center">{{ product.id }}</td>
                        <td class="text-center">{{ product.name.en }} / {{ product.name.fr }}</td>
                        <td class="text-center">{{ product.ean }}</td>
                        <td class="text-center" style="overflow-wrap: anywhere"><a :href="product.image_url" target="_blank">{{ product.image_url }}</a></td>
                        <td class="d-flex flex-row justify-content-center w-100"><b-button @click="redirectToEditPage(product.id)" variant="primary"><b-icon-pencil class="mr-1"></b-icon-pencil></b-button></td>
                    </tr>
                </tbody>
            </table>
            <template #modal-footer>
                <button type="button" class="btn btn-primary" @click="$bvModal.hide('images-modal'); $bvModal.show('response-modal')">{{ $t('importCsvResult') }}</button>
            </template>
        </b-modal>
    </section>
</template>

<script>
import Papa from "papaparse";
const utils = require('@/assets/js/utils');

export default {
    name: "ImportCsv",
    data() {
        return {
            csvFile: null,           // The csv file
            missingColumns: [],      // The list of missing columns
            newDataset: [],          // The new dataset extract from the csv file
            isAGoodFile: true,       // If the file is good or not
            wrongProducts: [],       // The number of wrong products
            categories: [],          // The categories
            chosenCategory: null,    // The chosen category
            tabExistingProducts: [], // The existing products return by the server
            tabNewProducts: [],      // The new products return by the server
            tabImages: [],           // The products images encoded in base64
            tabBadImages: [],        // The id and ean tab of products which have bad image
            numberNewProducts: 0,    // The number of new products
            forceSend: false         // If the user wants to send the new dataset even if there are existing products
        }
    },
    methods: {
        // Import the csv file and parse it
        importCsv(event) {
            // Reinitialize the newDataset and isAGoodFile variables
            this.newDataset = [];
            this.isAGoodFile = true;
            this.$refs.isNotGoodFileWarn.style.display = "none";
            this.wrongProducts = [];
            this.missingColumns= [];

            // Get the csv file
            this.csvFile = event.target.files[0];
            let reader = new FileReader();

            // Read the csv file
            reader.onload = (e) => {
                let csvContent = e.target.result;
                Papa.parse(csvContent, {
                    complete: (results) => {
                        // Get the columns of the csv file
                        let columns = results.data[0];
                        // Get the category id
                        let category_id = this.getCategoryId();

                        // Check if the csv file contains all the required columns
                        if (this.checkIfContainsAllColumns(columns)) {
                            // Create a product object from one line of the csv file
                            for (let i = 1; i < results.data.length; i++) {
                                let row = results.data[i];
                                let product = this.createProduct(columns, row, category_id);
                                this.newDataset.push(product);
                            }
                            // Check if the products are good
                            for (let product of this.newDataset)
                                if (!this.isAGoodProduct(product))
                                    this.wrongProducts.push(this.newDataset.indexOf(product) + 1);
                        }
                        // If the file is not good, display a warning message
                        else {
                            this.isAGoodFile = false;
                            this.$refs.isNotGoodFileWarn.style.display = "block";
                            this.$refs["submit-button"].disabled = true;
                        }
                    }
                });
            };
            // Read the csv file if is good
            if (this.isAGoodFile)
                reader.readAsText(this.csvFile);
        },
        //Check if category has been chosen
        checkCategory() {
            if (this.$refs.categoryInput.value === "" || this.$refs.categoryInput.value === null)
                this.$refs["csv-file-input"].disabled = true;
            else
                this.$refs["csv-file-input"].disabled = false;
        },
        // Check if the csv file contains all the required columns
        checkIfContainsAllColumns(columns) {
            // The required columns
            const requiredColumns = ['name_en', 'name_fr', 'keywords_en', 'keywords_fr', 'description_en', 'description_fr', 'brand', 'price', 'ean', 'image_url'];

            // If the number of columns is different from the number of required columns, the file is not good
            if (columns.length !== requiredColumns.length) {
                this.$refs.isNotGoodFileWarn.style.display = "block";
                this.$refs["submit-button"].disabled = true;
                return false;
            }

            // If the columns are different from the required columns, the file is not good
            for (let i = 0; i < requiredColumns.length; i++) {
                if (!columns.includes(requiredColumns[i])) {
                    this.missingColumns.push(requiredColumns[i]);
                    this.$refs.isNotGoodFileWarn.style.display = "block";
                    this.$refs["submit-button"].disabled = true;
                }
            }

            // If there is missing columns, returns false
            if (this.missingColumns.length !== 0)
                return false

            // If the file is good, hide the warning message and enable the submit button
            this.$refs.isNotGoodFileWarn.style.display = "none";
            this.$refs["submit-button"].disabled = false;
            return true;
        },
        // Create a product object from one line of the csv file
        createProduct(attributesName, datas) {
            // Initialize an empty product object
            let product = {
                name: {},
                keywords: {},
                description: {},
                category: null,
            };

            // Fill the product object with the datas of the csv file
            for (let i = 0; i < attributesName.length; i++) {
                switch (attributesName[i]) {
                    case 'name_en': // Necessary
                        product['name']['en'] = datas[i];
                        break;
                    case 'name_fr': // Necessary
                        product['name']['fr'] = datas[i];
                        break;
                    case 'keywords_en': // Not necessary
                        product['keywords']['en'] = datas[i];
                        break;
                    case 'keywords_fr': // Not necessary
                        product['keywords']['fr'] = datas[i];
                        break;
                    case 'description_en': // Not necessary
                        product['description']['en'] = datas[i];
                        break;
                    case 'description_fr': // Not necessary
                        product['description']['fr'] = datas[i];
                        break;
                    case 'brand': // Necessary
                        product['brand'] = datas[i];
                        break;
                    case 'price': // Necessary
                        product['price'] = datas[i];
                        break;
                    case 'ean': // Necessary
                        product['ean'] = '';
                        if (datas[i] === '')
                            product['ean'] = utils.generateEanForCsv(product['ean']).toString();
                        else
                            product['ean'] = datas[i];
                        break;
                    case 'image_url': // Not necessary
                        product['image_url'] = datas[i];
                        break;
                    default:
                        break;
                }
            }
            return product;
        },
        // Transform image url to base 64 and resize it to max 282*282
        async imageUrlToBase64(url) {
            try {
                // Get the image from the url and verify that exists
                let data = await fetch(url);

                // Get the blob from hte image and verify that is an image
                const blob = await data.blob()
                if (!(blob.type.includes('jpg') ||
                      blob.type.includes('jpeg') ||
                      blob.type.includes('png') ||
                      blob.type.includes('bmp') ||
                      blob.type.includes('gif') ||
                      blob.type.includes('tiff') ||
                      blob.type.includes('webp'))) {
                    return '';
                }
                // Return the base 64
                return new Promise((resolve, reject) => {
                    let img = new Image();
                    img.src = URL.createObjectURL(blob);
                    img.onload = () => {
                        let canvas = document.createElement('canvas');
                        let width = img.width;
                        let height = img.height;

                        // Define the width to maximum 282
                        if (width > height) {
                            if (width > 282) {
                                height *= 282 / width;
                                width = 282;
                            }
                        }
                        // Define the height to maximum 282
                        else {
                            if (height > 282) {
                                width *= 282 / height;
                                height = 282;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, width, height);
                        resolve(canvas.toDataURL());
                    };
                    img.onerror = reject;
                });
            } catch (e) {
                // If there is error, return an empty string
                return '';
            }
        },
        // Verify if the product contains all the necessary attributes
        isAGoodProduct(product) {
            return this.isNameValid(product.name.en) &&
                this.isNameValid(product.name.fr) &&
                this.isBrandValid(product.brand) &&
                this.isPriceValid(product.price) &&
                this.isEanValid(product.ean);
        },
        // Get the category id
        getCategoryId() {
            return this.categories.find(cat => cat.name === this.chosenCategory).id;
        },
        // Send the new dataset to the server
        async handleSubmit(force) {
            //Hide preview modal and show the loading modal
            this.$bvModal.hide('preview-modal');
            this.$bvModal.show('loading-modal');

            // If the user wants to send the new dataset even if there are existing products
            this.forceSend = force;


            if (!this.forceSend) {
                // Get the chosen category
                let chosen_category = this.getCategoryId();
                // Encoded all the url in the case that all products would be new
                for (let product of this.newDataset) {
                    // Create an object which contains ean and base64 encoded image from each product to send to server
                    let img = {
                        ean: product.ean,
                        base64: '',
                    }
                    img.base64 = await this.imageUrlToBase64(product.image_url);
                    if (img.base64 !== '') {
                        img.base64 = img.base64.split(',')[1];
                    }
                    this.tabImages.push(img);

                    // Add the category to product
                    product.category = chosen_category;
                }
            }
            // Delete all images of already existing products
            else {
                this.tabImages = this.tabImages.filter(image => this.tabNewProducts.find(ean => ean === image.ean) !== undefined);
            }

            // Create the object to send
            const formData = new FormData();
            formData.append('params', JSON.stringify(this.newDataset));
            formData.append('forceSend', this.forceSend);

            // Send the new dataset to the server
            this.$http.post(utils.getConfig().URL + 'catalog/product/save/list', formData)
                .then(async (response) => {
                    this.treatResponse(response);

                    // If not problem, send the products images
                    if (this.forceSend || this.tabExistingProducts.length === 0) {
                        // Reinitialize tab
                        this.tabBadImages = [];

                        // Send images
                        await this.sendImages();
                        // From the ws object, transform to an object which contains ean, id, names and image_url for the images modal
                        await this.treatBadImagesResponse();
                    }
                    // Hide loading modal and show images modal if there is error, response modal else
                    this.$bvModal.hide('loading-modal');
                    if (this.tabBadImages.length !== 0) {
                        console.log('Modal 1');
                        this.$bvModal.show('images-modal');
                    }
                    else {
                        console.log('Modal 2');
                        this.$bvModal.show('response-modal');
                    }
                });
        },
        // Get the server response
        treatResponse(response) {
            // Get the new products
            if (response.data.newProducts !== undefined)
                this.tabNewProducts = JSON.parse(response.data.newProducts);
            else
                this.tabNewProducts = undefined;
            // Get the existing products
            if (!this.forceSend) {
                this.tabExistingProducts = JSON.parse(response.data.existingProducts);
                this.numberNewProducts = this.tabNewProducts.length;
            } else {
                this.tabExistingProducts = [];
            }
        },
        // Send images by 10-packs
        async sendImages() {
             for (let i = 0; i < this.tabImages.length; i += 10) {
                let tmp = this.tabImages.slice(i, Math.min(i + 10, this.tabImages.length));

                const datas = new FormData();
                datas.append('params', JSON.stringify(tmp));
                let response = await this.$http.post(utils.getConfig().URL + 'catalog/product/save/list/images', datas)
                 // Get the id and ean of product which don't have images
                 this.tabBadImages = this.tabBadImages.concat(JSON.parse(response.data.errorProducts));
            }
        },
        // Verify if the name is valid
        isNameValid(name) {
            return name !== "" && name !== null && name !== undefined && name.length !== 0 && name.length <= 255 && typeof name === 'string';
        },
        // Verify if the brand is valid
        isBrandValid(brand) {
            return brand !== "" && brand !== null && brand !== undefined && brand.length !== 0 && brand.length <= 255 && typeof brand === 'string';
        },
        // Verify if the price is valid
        isPriceValid(price) {
            return price !== "" && price !== null && price !== undefined;
        },
        // Verify if the ean is valid
        isEanValid(ean) {
            return ean !== "" && ean !== null && ean !== undefined && ean.length === 13 && typeof ean === 'string' && ean.match(/^[0-9]+$/) !== null && this.newDataset.filter(prod => prod.ean === ean).length <= 1;
        },
        // Redirect to edit product page in a new tab
        redirectToEditPage(productId) {
            window.open('/product/edit/' + productId, '_blank');
        },
        // From the ws object, transform to an object which contains ean, id, names and image_url for the images modal
        async treatBadImagesResponse() {
            this.tabBadImages = this.tabBadImages.map((element) => {
                let object = {
                    ean: element.members.ean,
                    id: element.members.id,
                }
                let product = this.newDataset.find((product) => product.ean === object.ean);
                object.name = product.name;
                object.image_url = product.image_url;
                return object;
            });
        }
    },
    mounted() {
        // Get the categories from the server
        this.$http.get(utils.getConfig().URL + 'catalog/category/get/all/formated')
            .then((response) => {
                this.categories = response.data;
            });
    },
}
</script>

<style>
    .error-icon {
        width: 150px;
        height: 150px;
    }

    .error-icon img {
        width: 100%;
        height: 100%;
    }

    #duplicates-list {
        max-height: 150px;
        overflow-y: auto;
    }

    #template-link {
        width: fit-content;
        margin: 0 0 !important;
        padding: 0 0 !important;
    }

    #preview-modal .modal-dialog, #images-modal .modal-dialog {
        max-width: 90% !important;
    }
</style>